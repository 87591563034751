/* Variables. */
$primary: #93CC00;
$secondary: #FFB000;
$tertiary: #145719;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap');

$font-family-sans-serif: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Open Sans';
$btn-font-family: 'Open Sans';
$headings-font-family: 'Open Sans';
$body-font-family: 'Open Sans';
$body-font-size: 1.1rem;
$body-line-height: 1.9rem;

@import "../../flean_base_2019/sass/imports";
